import mAxios from "axios";
import axios from "axios";
// import { lastToken } from "@/router";

mAxios.defaults.withCredentials = true;
const mAxiosApi = mAxios.create({
  baseURL: process.env.VUE_APP_BACK_URL,
  withCredentials: true,
});

mAxiosApi.interceptors.response.use(
  function (response) {
    if (response.headers["x-xsrf-token"]) {
      document.cookie =
        "XSRF-TOKEN=" + response.headers["x-xsrf-token"] + "; path=/";
    }
    return response;
  },
  function (error) {
    // console.log(`mYerror ${String(route.name)}`, error.message);
    if (
      error.message.indexOf("Error") > 0 ||
      (error.message.indexOf(" 40") > 0 && window.location.hash !== "#/profil" )
    ) {
      const urlSign = `${
        window.location.protocol + "//" + window.location.host
      }/#/sign-in`;
      window.location.href = urlSign;
      /*
      Swal.fire({
        text: "Votre session semble avoir expirée, merci de vous reconnecter, svp",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Retourner sur la page de connexion",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      }).then((isConfirm) => {
        const urlSign = `${
          window.location.protocol + "//" + window.location.host
        }/#/sign-in`;
        if (isConfirm) {
          window.location.href = urlSign;
        } else {
          window.location.href = urlSign;
        }
      });
      */
    }
  }
);

mAxiosApi.prototype.getAxios = async function(request: string): Promise<any>{ 
  const response = await mAxiosApi.get(request
    // ,{cancelToken: lastToken && lastToken.token}
    )
  return response.data;
}

export default mAxiosApi;
