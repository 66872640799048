export const i18n_en = {
    "Board StillOnline": "Board StillOnline",
    "Retour": "Back",
    "CCO ID" : "CCO ID",
    "SMART ACCOUNT" : "SMART ACCOUNT",
    "Non renseigné" : "Unknown",
    "Addresse principale" : "Main address",
    "Votre interlocuteur Stillnetwork" : "Your Stillnetwork contact",
    "Votre interlocutrice Stillnetwork" : "Your Stillnetwork contact",
    "M." : "M.",
    "Mme." : "Ms.",
    "est joignable :" : "can be reached :",
    "Par mail" : "By mail",
    "Par téléphone" : "By Phone",
    "Oups...": "Oops...",
    "Retourner à l'accueil": "Go back to the homepage",
    "Mot de passe oublié ?": "Forgot password?",
    "Veuillez nous contacter au 01 60 92 41 00": "Please contact us at 01 60 92 41 00",
    "afin de procéder à la vérification de votre identité": "to proceed with identity verification",
    "et la réinitilisation de votre mot de passe.": "and password reset.",
    "Retour à la page de connexion": "Back to login page",
    "Identifiant": "Username",
    "Mot de passe": "Password",
    "Se connecter": "Login",
    "Chargement...": "Loading...",
    "Dernières mises à jours": "Latest updates",
    "Une nouvelle facture est disponible": "A new invoice is available",
    "Transmission d'une nouvelle offre commerciale": "Transmission of a new commercial offer",
    "concernant votre projet": "regarding your project",
    "Partage du fichier": "File sharing",
    "Notre offre": "Our offer",
    "Nos partenaires": "Our partners",
    "Nous contacter": "Contact us",
    "Nouveau !": "New!",
    "Chiffres clés": "Key figures",
    "Basés sur vos commandes": "Based on your orders",
    "Aucune commande": "No orders",
    "Il n'existe aucune commande à afficher correspondant à vos critères de recherche.": "There are no orders to display that match your search criteria.",
    "N° Commande Client": "Customer Order Number",
    "N° Offre Stillnetwork": "Stillnetwork Offer Number",
    "Contact Stillnetwork": "Stillnetwork Contact",
    "Date non communiquée": "Date not communicated",
    "Mise à jour": "Update",
    "Equipements": "Equipment",
    "Prestation": "Service",
    "Support / Abonnement": "Support / Subscription",
    "Détail de la commande": "Order Details",
    "Vue d'ensemble": "Overview",
    "Commandes en cours": "Current orders",
    "Vue équipements": "Equipment View",
    "Vue contrats": "Contracts View",
    "FAQ support": "Support FAQ",
    "Gestion des tickets": "Ticket Management",
    "Gestion des stocks": "Stock Management",
    "Gestion des sites": "Site Management",
    "Offres commerciales": "Commercial Offers",
    "Commandes": "Orders",
    "Factures": "Invoices",
    "Prochaines échéances": "Upcoming due dates",
    "Aucune échéance": "No due dates",
    "Il n'existe pour le moment aucune échéance à suivre.": "There are currently no due dates to follow.",
    "Aucune facture": "No invoices",
    "Il n'existe aucune facture à afficher correspondant à vos critères de recherche.": "There are no invoices matching your search criteria.",
    "Facture PDF": "Invoice PDF",
    "N° Contrat": "Contract Number",
    "Smart Account": "Smart Account",
    "Date de début": "Start Date",
    "Date de fin": "End Date",
    "Facture": "Invoice",
    "Date": "Date",
    "Echéance": "Due date",
    "Contact": "Contact",
    "Devise": "Currency",
    "Total HT": "Total Excl. Tax",
    "Total TTC": "Total Incl. Tax",
    "Total Réglement": "Total Payment",
    "Total Avoir": "Total Credit",
    "Solde": "Balance",
    "Facture soldée": "Invoice settled",
    "Offre": "Offer",
    "Livraison": "Delivery",
    "Délai de livraison": "Delivery Time",
    "Aucune offre": "No offers",
    "Il n'existe aucune offre à afficher correspondant à votre critère de recherche.": "There are no offers matching your search criteria.",
    "Offre PDF": "Offer PDF",
    "Fichier :": "File:",
    "Envoyer": "Send",
    "Concernant le projet :": "Regarding the project:",
    "Livraison estimée": "Estimated Delivery",
    "Offre Stillnetwork": "Stillnetwork Offer",
    "Montants HT / TTC": "Amounts Excl. Tax / Incl. Tax",
    "Contenu de la commande": "Order Contents",
    "Support et Abonnement": "Support and Subscription",
    "Documents": "Documents",
    "Document": "Document",
    "Aucune ligne à afficher": "No lines to display",
    "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.": "There are no lines to display matching your search criteria.",
    "En Stock Stillnetwork": "In Stillnetwork Stock",
    "Numéros de série": "Serial Numbers",
    "Historique dates": "Date History",
    "Concerne l'équipement :": "Regarding the equipment:",
    "Historique dates de livraison": "Delivery Date History",
    "Abonnement": "Subscription",
    "Support": "Support",
    "En attente": "Pending",
    "Traité": "Processed",
    "En cours de traitement": "In progress",
    "Equipements liés": "Related Equipment",
    "IMPORTANT": "IMPORTANT",
    "L'ouverture d'un ticket via l'interface Web ou par e-mail le définit automatiquement par défaut sur un ticket de priorité": "Opening a ticket via the web interface or email automatically sets it to a default priority ticket.",
    "ou": "or",
    "Si vous avez un cas de priorité": "If you have a priority case",
    "vous devez ouvrir le ticket avec le GCC par téléphone.": "you must open the ticket with the GCC by phone.",
    "Via l'interface CISCO": "Via the CISCO interface",
    "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface CISCO dédié en cliquant sur le lien suivant :": "To open a ticket on your support contract, you can access the dedicated CISCO interface by clicking on the following link:",
    "Via l'envoi d'un Email": "Via sending an email",
    "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail à :": "You can also open a ticket on your support contract by sending an email to:",
    "Via un appel téléphonique": "Through a phone call",
    "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.<br>Les numéros actuels sont listés ci-dessous :": "There are several different phone numbers to contact the TAC, depending on your location in the world. <br> The current numbers are listed below:",
    "Asia-Pacific  :": "Asia-Pacific:",
    "North America  :": "North America:",
    "EMEA   :": "EMEA:",
    "CISCO - Support manager": "CISCO - Support manager",
    "Vous pouvez également ouvrir un ticket sur votre contrat de support en envoyant un mail sur l'adresse": "You can also open a ticket on your support contract by sending an email to the address",
    "Il existe plusieurs numéros de téléphone différents à utiliser pour contacter le TAC, en fonction de votre emplacement dans le monde.": "There are several different phone numbers to contact the TAC, depending on your location in the world.",
    "Les numéros actuels sont listés ci-dessous :": "The current numbers are listed below:",
    "Extreme Networks": "Extreme Networks",
    "Via l’interface Extreme Networks": "Via the Extreme Networks interface",
    "Pour ouvrir un ticket de support, il convient de se connecter sur le portail en cliquant sur le lien suivant :": "To open a support ticket, you need to log in to the portal by clicking on the following link:",
    "(renseigner son e-mail et son mot de passe)": "(enter your email and password)",
    "Vous pouvez contacter le centre d’assistance d’Extreme Network pour obtenir de l’aide en sélectionnant votre continent puis le pays d’origine sur la page suivante :": "You can contact the Extreme Network assistance center for help by selecting your continent and then the country of origin on the following page:",
    "Vous trouverez alors le numéro à contacter depuis votre pays.": "You will find the number to contact from your country.",
    "Depuis la France :": "From France:",
    "Si vous rencontrez des problèmes avec le numéro sans frais, essayez-le +1-408-579-2800 comme numéro de téléphone alternatif pour joindre GTAC/RMA.": "If you encounter issues with the toll-free number, try +1-408-579-2800 as an alternative phone number to reach GTAC/RMA.",
    "Via l'interface FortiCare": "Via the FortiCare interface",
    "FortiCare - Support manager": "FortiCare - Support manager",
    "Afin de procéder à l'ouverture d'un ticket sur votre contrat de support, vous pouvez accèder à l'interface Fortinet dédié en cliquant sur le lien suivant :": "To open a ticket on your support contract, you can access the dedicated Fortinet interface by clicking on the following link:",
    "Via un chat instantané": "Through live chat",
    "Vous pouvez également discuter en direct avec un technicien Fortinet, accédez au chat en": "You can also chat live with a Fortinet technician, access the chat by",
    "cliquant sur ce lien": "clicking on this link",
    "Il existe 2 numéros de téléphone différents pour contacter directement le support Fortinet  :": "There are 2 different phone numbers to directly contact Fortinet support:",
    "Numéro Gratuit  :": "Toll-Free Number:",
    "Numéro payant  :": "Paid Number:",
    "JUNIPER - Support manager": "JUNIPER - Support manager",
    "Via l'interface JUNIPER": "Via the JUNIPER interface",    
    "Pour trouver des informations de support détaillées sur votre produit, rendez-vous sur la page :": "To find detailed support information for your product, visit the page:",
    "ou connectez-vous avec votre compte Juniper sur la page :": "or login with your Juniper account on the page:",
    "Via le chat Juniper": "Via the Juniper chat",
    "Pour l’assistance mondiale, utilisez l'icône Chat en bas à droite de la page :": "For global assistance, use the Chat icon at the bottom right of the page:",
    "pour demander une assistance 24 heures sur 24, 7 jours sur 7.": "to request 24/7 assistance.",
    "Depuis la France, composez le": "From France, dial",
    "Depuis l’étranger, utilisez un numéro de pays répertorié dans l’un des onglets de la page :": "From abroad, use a country-specific number listed in one of the tabs on the page:",
    "Polycom": "Polycom",
    "Vous pouvez contacter l’assistante par téléphone :": "You can contact support by phone:",
    "Pour le support contractuel": "For contractual support",
    "Assistance en anglais disponible 24h / 24 et 7j / 7": "English support available 24/7",
    "Pour le support standard": "For standard support",
    "Du lundi au vendredi - de 9h00  à 17h30": "Monday to Friday - 9:00 AM to 5:30 PM",
    "Depuis la france :": "From France:",
    "Pour contacter le support partout dans le monde, vous pouvez utiliser les numéros que vous trouverez dans la liste via le lien :": "To contact support worldwide, you can use the numbers found in the list via the link:",
    "Via un formulaire": "Via a form",
    "Vous devez compléter le formulaire d’assistante sur la page suivante :": "You need to fill out the support form on the following page:",
    "SYNOLOGY": "SYNOLOGY",
    "Via l’interface Synology": "Via the Synology interface",
    "Pour soumettre un ticket d’assistance ou suivre son statut, connectez-vous à votre compte Synology en cliquant sur le lien suivant :": "To submit a support ticket or track its status, log in to your Synology account by clicking on the following link:",
    "Le service client Synology de France est joignable du lundi au vendredi de 9h à 17h :": "The Synology customer service in France is available Monday to Friday from 9:00 AM to 5:00 PM:",
    "Constructeurs": "Manufacturers",
    "Parc client sous support": "Customer support under warranty",
    "Par gamme de produits": "By product range",
    "Aucun équipement": "No equipment",
    "Il n'existe pour le moment aucun équipement sous support dans notre base.": "There are currently no supported equipment in our database.",
    "Equipement": "Equipment",
    "Vue par équipement": "View by equipment",
    "Vue par contrat": "View by contract",
    "CONTRAT ACTIF": "ACTIVE CONTRACT",
    "CONTRAT INACTIF": "INACTIVE CONTRACT",
    "CONTRAT ARCHIVE": "ARCHIVED CONTRACT",
    "Contrat sans libellé": "Contract without label",
    "Echéance au": "Expiration on",
    "Echéance inconnue": "Expiration unknown",
    "Archiver le contrat": "Archive the contract",
    "Désarchiver le contrat": "Unarchive the contract",
    "Demande de renouvellement": "Renewal request",
    "Cette demande sera envoyée directement à votre interlocuteur Stillnetwork, qui reprendra contact avec vous rapidement": "This request will be sent directly to your Stillnetwork contact, who will get back to you soon",
    "afin de faire le point sur votre besoin.": "to discuss your requirements.",
    "Annuler": "Cancel",
    "Envoyer votre demande": "Send your request",
    "Ouvrir un ticket de support": "Open a support ticket",
    "Aucun Contrat": "No Contract",
    "Il n'existe aucune ligne dans le contrat à afficher correspondant à vos critères de recherche.": "There are no lines in the contract to display that match your search criteria.",
    "SN :": "SN:",
    "Du": "From",
    "au": "to",
    "Garantie jusqu'au": "Warranty until",
    "Hors garantie depuis le": "Out of warranty since",
    "Matériel supporté jusqu'au": "Hardware supported until",
    "Offre commerciale N°": "Commercial offer No.",
    "du": "from",
    "Discussion autour du ticket": "Discussion about the ticket",
    "T'Chat global Still ERP !": "Global Still ERP Chat!",
    "Votre commande": "Your order",
    "Aucune offre à afficher.": "No offer to display.",
    "Date de livraison": "Delivery date",
    "Livraison le": "Delivery on",
    "mise à jour le": "updated on",
    "Référence / Désignation": "Reference / Designation",
    "Qté": "Qty",
    "Statut": "Status",
    "Actions": "Actions",
    "La date de livraison n'a connue aucune variation depuis le": "The delivery date has not changed since",
    "la date de livraison est passée du": "the delivery date changed from",
    "Projet": "Project",
    "Demandeur": "Requester",
    "Montants HT": "Amounts excl. tax",
    "Afficher": "Display",
    "Demandeurs": "Requesters",
    "Status": "Status",
    "Rechercher dans la liste": "Search in the list",
    "Filter par": "Filter by",
    "Oui": "Yes",
    "Non": "No",
    "Exporter et télécharger la liste au format Excel ?": "Export and download the list in Excel format?",
    "Exporter": "Export",
    "Facture N°": "Invoice No.",
    "DU": "FROM",
    "AU": "TO",
    "Voulez-vous vraiment archiver ce contrat ?": "Do you really want to archive this contract?",
    "OUI": "YES",
    "Non, ne pas archiver": "No, do not archive",
    "Voulez-vous vraiment désarchiver ce contrat ?": "Do you really want to unarchive this contract?",
    "Non, ne pas désarchiver": "No, do not unarchive",
    "Facultatif : Veuillez saisir ici un complément d'information concernant votre demande de renouvellement.": "Optional: Please enter additional information regarding your renewal request here.",
    "Ouvrir un case CISCO": "Open a Cisco case",
    "Déployer toute les références majeures": "Deploy all major references",
    "Aucune ligne de contrat à afficher.": "No contract lines to display.",
    "Produit": "Product",
    "Période support": "Support period",
    "Serv. Level": "Service level",
    "Garantie": "Warranty",
    "Site": "Site",
    "Type de support": "Type of support",
    "Succés": "Success",
    "Demande de renouvellement envoyée à votre interlocuteur Stillnetwork.": "Renewal request sent to your Stillnetwork contact.",
    "Archivage": "Archiving",
    "Ce contrat est maintenant considéré comme": "This contract is now considered as",
    "archivé": "archived",
    "désarchivé": "unarchived",
    "Abonnements": "Subscriptions",
    "Licence": "License",
    "Contrats de support": "Support contracts",
    "Support matériel / Logiciel": "Hardware / Software support",
    "Parc client": "Customer park",
    "Nombre d'équipements": "Number of equipment",
    "Outil de déploiement": "Deployment tool",
    "Nombre de tickets": "Number of tickets",
    "Contrat": "Contract",
    "matériel": "hardware",
    "Ticket": "Ticket",
    "Aucune": "None",
    "Aucun": "No",
    "Aucun ticket": "No ticket",
    "Date commande": "Order date",
    "Contenu": "Content",
    "Aucune commande à afficher.": "No order to display.",
    "Numéro de facture": "Invoice number",
    "Aucune facture à afficher.": "No invoice to display.",
    "Ecrire un nouveau message...": "Write a new message...",
    "en cours": "in progress",
    "expire dans moins de 3 mois": "expires in less than 3 months",
    "expiré(s)": "expired(s)",
    "N°": "No.",
    "Détail de votre contrat": "Details of your contract",
    "Demande de renouvellement concernant de l'équipement": "Renewal request concerning equipment",
    "Equipements prochainement non supportés": "Equipment soon no longer supported",
    "Fin de support de": "End of support for",
    "Contrat N°": "Contract No.",
    "Détail de l'équipement": "Equipment details",
    "Vos données de contrats à importer": "Your contract data to import",
    "Attention,": "Attention,",
    "incohérences détéctées, veuillez les corriger avant de continuer, svp.": "inconsistencies detected, please correct them before continuing, please.",
    "Votre fichier semble valide, vous pouvez dés à présent lancer l'import.": "Your file seems valid, you can now start the import.",
    "Importer la liste de contrat": "Import the contract list",
    "Matrice Contrats Stillnetwork": "Stillnetwork Contract Matrix",
    "Tout d'abord, afin de pouvoir importer vos contrats, veuillez": "First of all, in order to import your contracts, please",
    "cliquer ici pour télécharger notre matrice": "click here to download our matrix",
    "Le fichier Excel téléchargé comporte deux onglets. Le premier 'Informations' vous guidera sur la saisie des champs, le second 'Contrats' sera votre référentiel de contrats que nous importerons.": "The downloaded Excel file has two tabs. The first 'Information' will guide you in entering the fields, the second 'Contracts' will be your contract repository that we will import.",
    "Upload de votre fichier": "Upload your file",
    "Veuillez cliquer pour afficher l'explorateur et importer votre fichier.": "Please click to display the explorer and import your file.",
    "Vérification de la cohérence de votre fichier": "Checking the consistency of your file",
    "Attention, seule la matrice Excel fournie ci-dessus peut être importée, veuillez noter que le fichier ne peut être supérieur à 2 MO.": "Attention, only the above provided Excel matrix can be imported, please note that the file cannot be larger than 2 MB.",
    "ID:": "ID:",
    "Parent:": "Parent:",
    "Import de vos données en cours": "Importing your data in progress",
    "Upload": "Upload",
    "Envoyer la matrice": "Send the matrix",
    "Vérification": "Verification",
    "Test de cohérence du fichier": "File coherence test",
    "Traitement": "Processing",
    "Import des données": "Data import",
    "N° de contrat vide": "Empty contract No.",
    "Aucun constructeur": "No manufacturer",
    "Référence vide": "Empty reference",
    "Aucune description": "No description",
    "N° de série vide": "Empty serial number",
    "Date de début vide": "Empty start date",
    "Date de fin vide": "Empty end date",
    "Succès": "Success",
    "Import de vos contrats réalisé avec succès !": "Successfully imported your contracts!",
    "Importer des contrats / Equipements": "Import contracts / Equipment",
    "Il n'existe aucun contrat à afficher correspondant à vos critères de recherche.": "There are no contracts to display that match your search criteria.",
    "Contrat archivé": "Archived contract",
    "Contrat importé": "Imported contract",
    "SmartAccount:": "SmartAccount:",
    "commande": "order",
    "s": "s",
    "Aucune commande liée": "No linked order",
    "Détail du contrat": "Contract details",
    "Contrat précédent": "Previous contract",
    "Contrat suivant": "Next contract",
    "Annuler l'import": "Cancel import",
    "Importer des contrat de support": "Import support contracts",
    "Type": "Type",
    "Libellé du contrat": "Contract label",
    "Période": "Period",
    "SmartAccount": "SmartAccount",
    "Constructeur": "Manufacturer",
    "L'identifiant doit être un email valide": "The identifier must be a valid email",
    "L'identifiant est obligatoire": "The identifier is required",
    "Le mot de passe est obligatoire": "The password is required",
    "Connexion établie !": "Connection established!",
    "Bienvenue": "Welcome",
    "sur votre board StillOnline !": "on your StillOnline board!",
    "Accèder à StillOnline": "Access StillOnline",
    "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.": "There seems to be an error, please check your login credentials.",
    "Retourner sur la page de connexion": "Return to the login page",
    "expiration(s) avant M-3": "expiration(s) before M-3",
    "contrat": "contract",
    "Aucune contrat lié": "No linked contract",
    "Détail du produit": "Product details",
    "Aucun contrat à afficher.": "No contract to display.",
    "Gamme": "Range",
    "Quantité": "Quantity",
    "Contrats": "Contracts",
    "Type de produit": "Product type",
    "Vos contrats à échéance": "Your expiring contracts",
    "contrat(s) expirés ou bientôt expirés": "expired or soon to expire contract(s)",
    "Il n'existe pour le moment aucune échéance de contrat à suivre.": "There are currently no contract expiration dates to follow.",
    "Contrat actif jusqu'au": "Active contract until",
    "Contrat inactif depuis le": "Inactive contract since",
    "Dernières mises à jours sur vos tickets": "Last updates on your tickets",
    "Vous n'avez créer aucun ticket pour le moment.": "You have not created any tickets at the moment.",
    "Dates": "Dates",
    "CCO / Smart Account": "CCO / Smart Account",
    "TICKET": "TICKET",
    "Validation du ticket": "Ticket validation",
    "Votre interlocuteur Stillnetwork vérifiera la bonne cohérence de cette demande, aucune action ne sera déclenchée avant sa validation.": "Your Stillnetwork contact will check the consistency of this request, no action will be taken before its validation.",
    "Nos équipes ont reçu une notification et traiteront votre demande dans les plus brefs délais.": "Our teams have received a notification and will process your request as soon as possible.",
    "Commentaires Stillnetwork :": "Stillnetwork comments:",
    "Fichier(s) concernant la validation :": "File(s) concerning the validation:",
    "Staging": "Staging",
    "Stager le matériel avec les configurations suivantes :": "Stage the equipment with the following configurations:",
    "Afficher la liste complète...": "Show the complete list...",
    "Masquer une partie de la liste...": "Hide part of the list...",
    "Expédition": "Shipment",
    "Sortie du stock du matériel suivant :": "Release of the following material from stock:",
    "Quantité :": "Quantity:",
    "Prévision de stock :": "Stock forecast:",
    "Expédition du matériel dés que possible à l'adresse :": "Shipment of the material as soon as possible to the address:",
    "Expédition du matériel à partir du": "Shipment of the material starting from",
    "à l'adresse :": "to the address:",
    "Blocage du matériel en vue d'une livraison future.": "Blocking of the material for future delivery.",
    "Notes spécifiques à destination de l'équipe Stilnetwork gérant le stock :": "Specific notes for the Stilnetwork team managing the stock:",
    "Fichier(s) concernant l'expédition :": "File(s) concerning the shipment:",
    "Déploiement" : "Deployment",
    "Description de votre besoin de déploiement :" : "Description of your deployment needs:",
    "Fichier(s) concernant le déploiement :" : "File(s) related to the deployment:",
    "Lieu du déploiement :" : "Deployment location:",
    "Contact :" : "Contact:",
    "Description de votre besoin :" : "Description of your need:",
    "Prestation de service :" : "Service provision:",
    "Première date d'intervention souhaitée le" : "First desired intervention date on",
    "Seconde date d'intervention souhaitée le" : "Second desired intervention date on",
    "Troisième date d'intervention souhaitée le" : "Third desired intervention date on",
    "Intervention du technicien en date du" : "Technician's intervention on",
    "Intervention du technicien en cours de planification." : "Technician's intervention being planned.",
    "Technicien effectuant la prestation :" : "Technician performing the service:",
    "Fichier(s) joint :" : "Attached file(s):",
    "Supprimer" : "Delete",
    "Adresse :" : "Address:",
    "Modifier les commentaires Stillnetwork :" : "Modify Stillnetwork comments:",
    "Appliquer" : "Apply",
    "Facturation" : "Billing",
    "Adresse de facturation :" : "Billing address:",
    "Commentaires modifié" : "Comments modified",
    "Saisir vos commentaires" : "Enter your comments",
    "Ce ticket est en attente de validation" : "This ticket is awaiting validation",
    "Ce ticket nécessitera dans un premier temps une validation de Stillnetwork" : "This ticket will first require validation from Stillnetwork",
    "Ce ticket a été validé" : "This ticket has been validated",
    "Ce ticket a été expédié" : "This ticket has been dispatched",
    "Ce ticket a été déployé" : "This ticket has been deployed",
    "Ce ticket a été facturé" : "This ticket has been billed",
    "Oui, le supprimer !" : "Yes, delete it!",
    "Opération irréversible, voulez-vous supprimer ce fichier ?" : "Irreversible operation, do you want to delete this file?",
    "Validation" : "Validation",
    "Retour à la gestion des sites" : "Return to site management",
    "Informations" : "Information",
    "Généralités sur le site" : "General information about the site",
    "Adresse détaillée" : "Detailed address",
    "Où se trouve le site ?" : "Where is the site located?",
    "Contacts" : "Contacts",
    "Sélection des contacts du site" : "Selection of site contacts",
    "Et quelques informations facultatives..." : "And some optional information...",
    "Informations principales du site" : "Main site information",
    "Libellé du site" : "Site label",
    "Afficher ce site sur la carte ?" : "Display this site on the map?",
    "Si décoché, le site sera tout de même affiché dans le listing des sites." : "If unchecked, the site will still be displayed in the site listing.",
    "Sélectionnez le type de site" : "Select the site type",
    "Site de livraison" : "Delivery site",
    "Livraison d'équipement" : "Equipment delivery",
    "Siège sociale / Centre d'affaires" : "Headquarters / Business center",
    "Adresse administrative" : "Administrative address",
    "Site de stockage" : "Storage site",
    "Centre logistique / Emplacement stratégique" : "Logistic center / Strategic location",
    "Localisation exacte de votre site." : "Exact location of your site.",
    "Adresse (Numéro et rue)" : "Address (Number and street)",
    "Complément" : "Additional information",
    "Précision sur le bâtiment, la porte spécifique, l'accès..." : "Details about the building, specific door, access...",
    "Code postal / Ville / Pays" : "Postal code / City / Country",
    "Géolocalisation" : "Geolocation",
    "Rechercher les coordonnées de l'adresse du site" : "Search for the coordinates of the site address",
    "Veuillez sélectionnez les personnes liées au site.": "Please select the individuals related to the website.",
    "Créer un nouveau contact": "Create a new contact",
    "Coordonnées": "Contact Information",
    "Monsieur": "Mr.",
    "Madame": "Mrs.",
    "Créer le contact": "Create Contact",
    "Dernière étape !": "Last Step!",
    "Si vous le souhaitez, vous pouvez aouter des notes complémentaires concernant ce site.": "If you wish, you can add additional notes about this site.",
    "Veuillez patienter...": "Please wait...",
    "Continuer": "Continue",
    "Le libellé du site est obligatoire pour continuer.": "The site label is required to continue.",
    "Le libellé du type est obligatoire pour continuer.": "The type label is required to continue.",
    "Opération réalisée avec succés !": "Operation completed successfully!",
    "Ajouter un nouveau site": "Add a new site",
    "Création du site": "Site creation",
    "Ajout d'un nouveau site": "Adding a new site",
    "Modification d'un site": "Modifying a site",
    "Modifier le site": "Edit the site",
    "Comment souhaitez-vous nommer ce site ?": "How would you like to name this site?",
    "Type spécifique": "Specific Type",
    "Prénom": "First Name",
    "Retour à la liste de vos tickets": "Return to your ticket list",
    "Création du ticket": "Ticket creation",
    "Informations principales sur le ticket": "Key information about the ticket",
    "Libellé du ticket": "Ticket label",
    "Veuillez sélectionnez un ou plusieurs types de services": "Please select one or more types of services",
    "Liste du matériel": "List of equipment",
    "Veuillez spécifier les quantités à expédier sur vos produits.": "Please specify the quantities to be shipped for your products.",
    "Aucun équipement de stock": "No stock equipment",
    "Il n'existe aucun équipement de stock à afficher correspondant à vos critères de recherche.": "There is no stock equipment to display that matches your search criteria.",
    "Il existe": "There are",
    "référence(s) masquée(s), cliquer ici pour les afficher": "hidden reference(s), click here to display them",
    "échéance au": "deadline on",
    "actuellement disponible.": "currently available.",
    "Aucun stock": "No stock",
    "en réappro. dés le": "on replenishment from",
    "date en attente": "waiting date",
    "Quelques informations sont requises afin d'assurer la sortie du stock.": "Some information is required to ensure the stock is released.",
    "Concernant l'expèdition en elle même, vous souhaitez :": "Regarding the shipment itself, what would you like:",
    "Envoyer le matériel dés que possible": "Send the material as soon as possible",
    "Envoyer le matériel à partir d'une date": "Send the material from a specific date",
    "Bloquer le stock de matériel pour une livraison future": "Block the material stock for a future delivery",
    "Sortir le matériel du stock": "Remove the material from stock",
    "Veuillez choisir la date d'expédition ci-dessous": "Please choose the shipping date below",
    "Avez-vous une precision à apporter à notre équipe gérant le stock ?": "Do you have any specific details to provide to our stock management team?",
    "Adresse et Contact": "Address and Contact",
    "Veuillez parcourir les onglets ci-dessous afin de spécifier les adresses et contacts.": "Please browse the tabs below to specify addresses and contacts.",
    "L'adresse concernant": "The address concerning",
    "est-elle identique à l'adresse principale ?": "is it the same as the main address?",
    "Oui, c'est la même adresse": "Yes, it's the same address",
    "Non, l'adresse est différente.": "No, the address is different.",
    "Affichage des sites de la société": "Display of company sites",
    "Veuillez sélectionner une adresse et un contact dans la liste suivante :": "Please select an address and a contact from the following list:",
    "Aucun libellé": "No label",
    "Service Spécifique": "Specific Service",
    "Afin de vous offri la meilleure prestation, nous avons besoin d'informations spécifiques.": "In order to offer you the best service, we need specific information.",
    "Pourriez-vous décrire les élements souhaités": "Could you describe the desired elements",
    "pour la prestation d'ingénierie ?": "for the engineering service ?",
    "Quand souhaiteriez-vous que nous programmions l'intervention ?": "When would you like us to schedule the intervention?",
    "Première date privilégiée :": "First preferred date:",
    "Deuxième date :": "Second date:",
    "Troisième date :": "Third date:",
    "Existe-t-il des fichiers à nous transmettre ?": "Are there any files to be transmitted to us related to this service?",
    "Ajouter un fichier": "Add a file",
    "Transmettez-nous vos fichiers des configurations et les informations spécifiques sur vos matériels": "Send us your configuration files and specific information about your equipment",
    "Synthèse et étapes du ticket": "Summary and stages of the ticket",
    "Création d'un nouveau ticket": "Creating a new ticket",
    "Quantité Disponible": "Available Quantity",
    "Saisir quantité": "Enter quantity",
    "Fichiers": "Files",
    "Commentaires": "Comments",
    "Libellé": "Label",
    "Adresse": "Address",
    "informations": "information",
    "Généralités sur le ticket": "General information about the ticket",
    "Quel matériel est concerné ?": "What equipment is concerned?",
    "Configuration du matériel": "Equipment configuration",
    "Organisez la sortie de matériels": "Organize the material release",
    "Prestations d'ingénierie": "Engineering services",
    "Adresse et contact": "Address and contact",
    "Sélection de site(s)": "Site(s) selection",
    "Synthèse du ticket": "Ticket summary",
    "Et création de votre ticket.": "And creation of your ticket.",
    "Retour à la liste des tickets": "Return to the ticket list",
    "Attention, le champ": "Attention, the field",
    "ne peut être vide.": "cannot be empty.",
    "Attention, vous devez saisir une date.": "Attention, you must enter a date.",
    "Attention, vous devez sélectionner une adresse principale.": "Attention, you must select a main address.",
    "Attention, aucune référence commande n'a été sélectionnée pour la référence": "Attention, no order reference has been selected for the reference",
    "Attention, aucune quantité n'a été saisie.": "Attention, no quantity has been entered.",
    "Attention, la taille du fichier ne peut dépasser 10 MO !": "Attention, the file size cannot exceed 10 MB!",
    "Ticket créé avec succés !": "Ticket created successfully!",
    "Facultatif, si vous le souhaitez, vous pouvez nommer ce ticket.": "Optional, if you wish, you can name this ticket.",
    "choix de la date": "date choice",
    "Type d'intervention, nombre de jours, compétences techniques...": "Type of intervention, number of days, technical skills...",
    "Premier choix de date": "First date choice",
    "Second choix de date": "Second date choice",
    "Dernier choix de date": "Last date choice",
    "Commentaires spécifiques...": "Specific comments...",
    "Aucune ligne à afficher.": "No lines to display.",
    "Commande Stillnetwork :": "Stillnetwork Order:",
    "Adresse principale": "Main address",
    "de": "from",
    "Aucun site à afficher.": "No sites to display.",
    "Image(s) jointe(s) :": "Attached image(s):",
    "Oui, la supprimer !": "Yes, delete it!",
    "Opération irréversible, voulez-vous supprimer cette image ?": "Irreversible operation, do you want to delete this image?",
    "En quelques chiffres": "In a few numbers",
    "Créer un nouveau ticket": "Create a new ticket",
    "ticket": "ticket",
    "en cours de traitement": "being processed",
    "traité": "treated",
    "Référence": "Reference",
    "en stock": "in stock",
    "référence": "reference",
    "disponible": "available",
    "en réappro.": "on replenishment.",
    "épuisée": "exhausted",
    "Sites": "Sites",
    "sites": "sites",
    "Libellé :": "Label:",
    "Afficher les sites": "Display the sites",
    "Sur une carte": "On a map",
    "En listing": "In a list",
    "Aucun contact": "No contact",
    "Créer un nouveau site": "Create a new site",
    "Adresse (Numéro et rue": "Address (Number and street)",
    "Corporation": "Corporation",
    "Sole Proprietorship": "Sole Proprietorship",
    "Non-profit": "Non-profit",
    "Limited Liability": "Limited Liability",
    "General Partnership": "General Partnership",
    "Créer le site": "Create the site",
    "Vue rapide": "Quick view",
    "Mises à jour": "Updates",
    "Modifier les détails du site": "Modify site details",
    "Aucune livraison n'est prévue sur ce site": "No delivery is scheduled for this site",
    "autre": "other",
    "projet": "project",
    "Prochaine livraison prévue le": "Next delivery scheduled on",
    "Concerne le projet": "Concerns the project",
    "Livraison projet": "Project delivery",
    "Indisponible": "Unavailable",
    "dés le": "from",
    "Historique": "History",
    "Détails du stock": "Stock details",
    "Concerne votre équipement": "Concerns your equipment",
    "Stock en cours :": "Current stock:",
    "Quantité disponible :": "Quantity available:",
    "Aucune disponibilité pour le moment": "No availability at the moment",
    "Réapprovisionnement :": "Replenishment:",
    "Historique du stock": "Stock history",
    "Initialisation du stock :": "Stock initialization:",
    "par": "by",
    "Sortie du stock :": "Stock output:",
    "N° PO": "PO Number",
    "Qté Dispo / Réappro": "Qty Avail / Replenish",
    "Echéance stock": "Stock expiration",
    "Réapprovisionnement": "Replenishment",
    "Disponibilité": "Availability",
    "Synthèse de vos projets": "Summary of your projects",
    "Ticket(s)": "Ticket(s)",
    "Filtrer la liste :": "Filter the list:",
    "Il n'existe aucun ticket à afficher correspondant à vos critères de recherche.": "There are no tickets to display matching your search criteria.",
    "Afficher le ticket": "View ticket",
    "Détail du ticket": "Ticket details",
    "Ticket précédent": "Previous ticket",
    "Ticket suivant": "Next ticket",
    "Créer votre premier ticket": "Create your first ticket",
    "Synthèse et filtres": "Summary and filters",
    "Répartition par demandeurs :": "Distribution by requesters:",
    "Répartition par services :": "Distribution by services:",
    "Répartition par statuts :": "Distribution by statuses:",
    "Liste des tickets": "List of tickets",
    "Planning": "Scheduler",
    "cartographie": "mapping",
    "Vous n'avez créer aucun ticket pour le moment": "You haven't created any tickets at the moment",
    "Aucun ticket à afficher.": "No ticket to display.",
    "Création": "Creation",
    "Services": "Services",
    "Planification": "Scheduling",
    "Intervenant :": "Intervener:",
    "Commande": "Order",
    "Client": "Client",
    "Partiellement livrée": "Partially delivered",
    "Ref Devis": "Quote Ref",
    "N° BC Still'": "Still' BC No",
    "N° BC Client": "Client BC No",
    "Votre N° SO": "Your SO No",
    "Votre N° PO": "Your PO No",
    "N° SO Cisco": "Cisco SO No",
    "Fraicheur :": "Freshness:",
    "PDF": "PDF",
    "Livraison Partielle ?": "Partial Delivery?",
    "A définir": "To be defined",
    "fichier": "file",
    "Aucun fichier": "No file",
    "Joindre un nouveau fichier": "Attach a new file",
    "Chargement du fichier...": "File loading...",
    "Répartition par intervenant :": "Distribution by intervener:",
    "Répartition par statut :": "Distribution by status:",
    "Planifier": "Schedule",
    "Intervenant à définir": "Intervener to be defined",
    "Vous n'avez aucun ticket à traiter pour le moment": "You have no tickets to process at the moment",
    "Planning d'interventions": "Interventions schedule",
    "Intervenant": "Intervener",
    "Pièces jointes": "Attachments",
    "Impossible ! Aucune date d'intervention !" : "Impossible! No intervention date!",
    "Erreur" : "Error",
    "OK" : "OK",
    "Impossible ! Aucun intervenant défini !" : "Impossible! No defined performer!",
    'Une fois que le statut sera passé à "Réalisé", il vous sera impossible de modifier l\'intervenant et la date du ticket. Souhaitez-vous passer ce ticket en "Réalisé" ?': "Once the status is changed to 'Completed', it will be impossible for you to modify the performer and the date of the ticket. Do you want to mark this ticket as 'Completed'?",
    "Confirmation" : "Confirmation",
    "Non, annuler" : "No, cancel",
    "Fichier ajouté" : "File added",
    "Détails de votre profil utilisateur" : "Details of your user profile",
    "Prénom Nom" : "First Name Last Name",
    "Votre fonction" : "Your role",
    "Téléphone Fixe" : "Landline Phone",
    "Téléphone portable" : "Mobile Phone",
    "Votre Cisco CCO ID" : "Your Cisco CCO ID",
    "Enregistrer les changements" : "Save changes",
    "Veuillez patientez..." : "Please wait...",
    "Profil de connexion" : "Login profile",
    "Votre adresse Email" : "Your email address",
    "Veuillez saisir votre adresse Email" : "Please enter your email address",
    "Confirmer votre mot de passe" : "Confirm your password",
    "Modifier votre Email" : "Modify your email",
    "Changer votre adresse mail" : "Change your email address",
    "Le mot de passe doit faire minimum 8 caractères." : "The password must be at least 8 characters long.",
    "Mot de passe actuel" : "Current password",
    "Nouveau mot de passe" : "New password",
    "Confirmation de votre nouveau mot de passe" : "Confirmation of your new password",
    "Modifier votre mot de passe" : "Modify your password",
    "Changer votre mot de passe" : "Change your password",
    "Nom de famille" : "Last name",
    "Fonction" : "Role",
    "Téléphone" : "Phone",
    "Cisco CCO ID" : "Cisco CCO ID",
    "Email Address" : "Email Address",
    "Le prénom est obligatoire" : "First name is required",
    "Le nom est obligatoire" : "Last name is required",
    "La fonction est obligatoire" : "Role is required",
    "Télephone Fixe" : "Landline Phone",
    "Télephone Portable" : "Mobile Phone",
    "Email" : "Email",
    "Password" : "Password",
    "Mot de passe actuel obligatoire" : "Current password is required",
    "Current password" : "Current password",
    "Confirmation du nouveau Mot de passe obligatoire" : "Confirmation of the new password is required",
    "Les nouveaux mots des passes doivent être identiques" : "The new passwords must match",
    "Password Confirmation" : "Password Confirmation",
    "Changement de vos informations personnelles correctement effectué !" : "Successfully changed your personal information!",
    "Ok" : "OK",
    "Changement de votre Email correctement effectué !" : "Successfully changed your email!",
    "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp." : "Error when changing email. Please check your email address and password.",
    "Changement de votre mot de passe correctement effectué !" : "Successfully changed your password!",
    "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp." : "Error when changing password. Please check your information and try again.",
    "Paramètres du compte" : "Account settings",
    "Déconnexion" : "Logout",
    "Overview" : "Overview",
    "Liste des commandes en cours" : "List of current orders",
    "Liste des factures" : "List of invoices",
    "Liste des offres commerciales" : "List of commercial offers",
    "Détail d'une commande | Prestations" : "Order detail | Services",
    "Détail d'une commande" : "Order detail",
    "Détail d'une commande | Support et abonnements" : "Order detail | Support and subscriptions",
    "Gestion des Contrats de support & Abonnements" : "Support Contracts & Subscriptions Management",
    "Vos équipements sous contrat de support" : "Your equipment under support contract",
    "Votre nouveau projet" : "Your new project",
    "Outils de déploiement" : "Deployment tools",
    "Suivi des commandes fournisseur" : "Supplier order tracking",
    "Suivi des prestations" : "Service tracking",
    "Gestion des contrats" : "Contract management",
    "Gestion commerciale" : "Commercial management",
    "Gestion commerciale " : "Commercial management",
    "Numéro de Série" : "Serial Number",
    "IDs" : "IDs",
    "Source" : "Source",
    "Cohérence" : "Consistency",
    "Interface Web Cisco" : "Cisco Web Interface",
    "Accès par Email" : "Access by Email",
    "Accès par téléphone" : "Access by phone",
    "carte" : "map",
    "liste" : "list",
    "Changer de société" : "Change company",
    "Offre commerciale" : "Commercial offer",
    "Contrat de support" : "Support contract",
    "Total dû depuis le" : "Amount due since",
    "Date d'échéance au" : "Due date",
    "Offre valide jusqu'au" : "Offer valid until",
    "Contrat arrivant à échéance le" : "Contract expiring on",
    "Afficher la facture au format PDF" : "Display the invoice in PDF format",
    "Afficher le détail de l'offre" : "Display offer details",
    "Afficher le détail du contrat" : "Display contract details",
    "Offre validée" : "Validated offer",
    "Offre envoyée" : "Offer sent",
    "Commande livrée" : "Order delivered",
    "Demandeur non défini" : "Undefined requester",
    "Réglée" : "Paid",
    "Facture échue" : "Overdue invoice",
    "A régler" : "To be paid",
    "En attente de livraison" : "Awaiting delivery",
    "Livré" : "Delivered",
    "Gamme inconnue" : "Unknown range",
    "Hors garantie" : "Out of warranty",
    "Sous garantie" : "Under warranty",
    "Support expiré" : "Expired support",
    "Expiration dans moins de 3 mois" : "Expiration in less than 3 months",
    "En cours" : "In progress",
    "Enlèvement sur site": "On-site pickup",
    "Etude de couverture Wifi": "Wifi coverage study",
    "Service de pré validation par un membre Stillnetwork après création du ticket": "Pre-validation service by a Stillnetwork member after ticket creation",
    "Transmettez-nous votre configuration matériel": "Send us your hardware configuration",
    "Procédez à une sortie de stock et lancer l'expédtion de vos produits": "Proceed with stock withdrawal and initiate shipment of your products",
    "Faites une demande de prestations d'ingénierie qualifié": "Submit a request for qualified engineering services",
    "Servide de facturation client": "Client billing service",
    "Décommissionner du matériel": "Decommission equipment",
    "Effectuer une demande d'analyse de la couverture, de la fiabilité et des performances de votre réseau sans fil": "Submit a request for analysis of the coverage, reliability, and performance of your wireless network",
    "Service spécifique": "Specific service",
    "Disponible": "Available",
    "Réapprovisionnement en cours": "Replenishment in progress",
    "Sans réapprovisionnement": "No replenishment",
    "Offre déclinée": "Declined offer",
    "Production en cours": "Ongoing production",
    "Attention, les quantités doivent être des nombres entiers": "Note: quantities must be whole numbers",
    "Liste des équipements en stock": "Equipment in stock",
    "Réinitialiser": "Reset",
    "Voulez vous vraiment réinitialiser ?": "Do you really want to reset ?",
    "Tout expédier": "Ship all",
    "Ticket de support": "Support ticket",
    "Faire une demande de ticket de support": "Request a support ticket",
    "Still-au3 - audit complexe": "Still-au3 - complexe audit",
    "Effectuer une demande d'audit complexe et un relevé de passage du fourreau": "Perform a complex audit request and a sheath passage report",
    "Catégorisation du ticket :": "Ticket categorization:",
    "Type de ticket": "Ticket type",
    "Sélectionner Type de ticket": "Select Ticket Type",
    "Priorité": "Priority",
    "Sélectionner Priorité": "Select Priority",
    "Impact": "Impact",
    "Sélectionner Impact": "Select Impact",
    "Description de votre ticket :": "Description of your ticket:",
    "Veuillez saisir ici toutes les informations nescessaires à la prise en charge du ticket": "Please enter here all the necessary information for handling the ticket",
    "Afin de vous offir la meilleure prestation, nous avons besoin d'informations spécifiques.": "To provide you with the best service, we need specific information.",
    "Incident": "Incident",
    "Problème": "Problem",
    "Demande": "Request",
    "Changement": "Change",
    "Faible": "Low",
    "Moyen": "Medium",
    "Elevé": "High",
    "Critique": "Critical",
    "Aucun impact": "No impact",
    "Majeur": "Major",
    "Crise": "Crisis",
    "Statut d'intervention :": "Intervention status",
    "Intervention du technicien en cours de planificiation.": "Technician intervention currently being scheduled.",
    "Rechercher...": "Search...",
    "Vos recherches récentes": "Your recent searchs",
    "Aucun résultat": "No results",
    "Préférences": "Preferences",
    "Tickets": "Tickets",
    "Stock": "Stock",
    "résultats": "results",
    "Cliquer ici pour afficher les": "Click here to display all",
    "Recherchez et appuyez sur Entrée": "Search and click enter",
    "Offres": "Offers",
    "Suivi de prestations": "Service monitoring",
    "Rafraîchir": "Refresh",
    "Sociétés": "Companies",
    "Veuillez sélectionner une société.": "Please select a company.",
    "Veuillez sélectionner un contact.": "Please select a contact.",
    "Espace partenaire": "Partner space",
    "Filtre Sous Organisation": "Sub-Organization Filter",
    "Comparer les affectations de licences :" : "Compare License Assignments :",
    "Jusqu'au": "Until",
    "Sous totaux": "Subtotals",
    "Quantité totale au":"Total quantity at",
    "Licences affectées depuis le": "Licenses assigned since",
    "Aucune licence à afficher": "No licenses to display",
    "Il n'existe aucune licence à afficher correspondant à vos critères de recherche." : "There are no licenses to display that match your search criteria.",

}