import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";
// import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/iconChat",
    name: "iconChat",
    component: () => import("@/views/still/chat/iconChat.vue"),
  },
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:loginpassword",
        name: "sign-in-direct",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:loginpassword/:redirect",
        name: "sign-in-redirect",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/still/chat/chat.vue"),
  },
  {
    path: "/kanban",
    name: "kanban",
    component: () => import("@/views/still/kanban/kanban.vue"),
  },
  {
    path: "/kTicket/:ticid",
    name: "kTicketId",
    component: () => import("@/views/still/kanban/ticket.vue"),
  },
  {
    path: "/kTicket/",
    name: "kTicket",
    component: () => import("@/views/still/kanban/ticket.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },

  {
    path: "/loader/:name/:param?/:code?",
    name: "loader",
    component: () => import("@/components/Loader.vue"),
  },

  {
    path: "/",
    redirect: "/stillboard",
    name:'parentstillboard',
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/stillboard",
        name: "stillboard",
        redirect: "/stillboard/overview",
        component: () => import("@/views/still/board/dashboard.vue"),
        children: [
          {
            path: "overview",
            name: "Vue d'ensemble",
            component: () => import("@/views/still/board/boardOverview.vue"),
          },
          {
            path: "offres/:code?",
            name: "Offre commerciale",
            component: () =>
              import("@/views/still/board/offresCommerciales.vue"),
          },
          {
            path: "commandes/:code?",
            name: "Commande",
            component: () => import("@/views/still/board/commandes.vue"),
          },
          {
            path: "factures/:code?",
            name: "Facture",
            component: () => import("@/views/still/board/factures.vue"),
          },
        ],
      },
      {
        path: "/commande/:code?",
        name: "commande",
        redirect: "/commande/:code/livraisons",
        component: () => import("@/views/still/commandes/headerCommande.vue"),
        children: [
          {
            path: "livraisons",
            name: "livraisons",
            component: () =>
              import("@/views/still/commandes/LivraisonsCommande.vue"),
          },
          {
            path: "support",
            name: "support",
            component: () =>
              import("@/views/still/commandes/SupportCommande.vue"),
          },
        ],
      },
      {
        path: "/profil",
        name: "profil",
        component: () => import("@/views/still/Profil.vue"),
      },
      {
        path: "/adminuser",
        name: "adminuser",
        component: () => import("@/views/still/adminuser.vue"),
      },
      {
        path: "/ssOrgaMeraki/:code?",
        name: "ssOrgaMeraki",
        component: () => import("@/views/still/contrats/sousOrgaMeraki.vue"),
      },
      {
        path: "/contrats",
        name: "contrats",
        redirect: "/contrats/overview",
        component: () => import("@/views/still/contrats/contrats.vue"),
        children: [
          {
            path: "contrats_overview",
            name: "contrats_overview",
            component: () => import("@/views/still/contrats/contrats_overview.vue"),
          },
          {
            path: "list",
            name: "list",
            component: () => import("@/views/still/contrats/list.vue"),
          },
          {
            path: "listEquipement",
            name: "listEquipement",
            component: () => import("@/views/still/contrats/listEquipement.vue"),
          },
          {
            path: "listEquipementbyType/:code",
            name: "listEquipementbyType",
            component: () => import("@/views/still/contrats/listEquipement.vue"),
          },
          {
            path: "case",
            name: "case",
            component: () => import("@/views/still/contrats/case.vue"),
          },
        ],
      },
      {
        path: "ea",
        name: "ea",
        component: () => import("@/views/still/contrats/ea.vue"),
      },
      {
        path: "eaMeraki/:code?",
        name: "eaMeraki",
        component: () => import("@/views/still/contrats/eaMeraki.vue"),
      },
      {
        path: "/stilldeploy",
        name: "stilldeploy",
        redirect: "/stilldeploy/overview",
        component: () => import("@/views/still/deploy/deploy.vue"),
        children: [
          {
            path: "overview",
            name: "overview",
            component: () => import("@/views/still/deploy/overview.vue"),
          },
          {
            path: "ticket",
            name: "ticket",
            component: () => import("@/views/still/deploy/ticket.vue"),
          },
          {
            path: "nticket",
            name: "nticket",
            component: () => import("@/views/still/deploy/nticket.vue"),
          },
          {
            path: "ticket/:ticid",
            name: "ticketWid",
            component: () => import("@/views/still/deploy/ticket.vue"),
          },
          {
            path: "ticket/:status",
            name: "ticketWst",
            component: () => import("@/views/still/deploy/ticket.vue"),
          },
          {
            path: "stock",
            name: "stock",
            component: () => import("@/views/still/deploy/stock.vue"),
          },
          {
            path: "stock/:code?",
            name: "stock",
            component: () => import("@/views/still/deploy/stock.vue"),
          },
          {
            path: "sites",
            name: "sites",
            component: () => import("@/views/still/deploy/sites.vue"),
          },
          {
            path: "sites/:code?",
            name: "sites",
            component: () => import("@/views/still/deploy/sites.vue"),
          },
          {
            path: "historique",
            name: "historique",
            component: () => import("@/views/still/deploy/overview.vue"),
          },
        ],
      },
      {
        path: "addupdatesite/:code",
        name: "addupdatesite",
        component: () => import("@/views/still/deploy/CreateEditSite.vue"),
      },
      {
        path: "addupdateticket",
        name: "addupdateticket",
        component: () => import("@/views/still/deploy/CreateTicket.vue"),
      },
      {
        path: "addupdateticket/:prc_knum_client?",
        name: "addupdateticket",
        component: () => import("@/views/still/deploy/CreateTicket.vue"),
      },
      {
        path: "suivicommande",
        name: "suivicommande",
        component: () => import("@/views/still/fournisseur/suivicommande.vue"),
      },
      {
        path: "prestaticket",
        name: "prestaticket",
        component: () => import("@/views/still/fournisseur/ticketPresta.vue"),
      },
    ],
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const CancelToken = axios.CancelToken
// export let lastToken
// let firstRequest = true

router.beforeEach(async (to, from, next) => {
  // if (firstRequest === false) {
  //   lastToken && lastToken.cancel('Operation canceled')
  //   lastToken = CancelToken.source()
  // }else{
  //   firstRequest = false
  // }

  if (to.path.indexOf("/commande/") === -1 || to.path.indexOf("/commande/") === -1) {
    store.commit("setUrlHistoMutation", to.path)
  }

  await store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);

  setTimeout(() => { window.scrollTo(0, 0); }, 100);

  /*
  if (store.getters.isUserAuthenticated){
    const myList = await mAxiosApi.prototype.getAxios("/getAccess/" + window.btoa(to.fullPath));
    // console.log(myList.results,  to.fullPath);
    if (myList.results != to.fullPath)  console.log(myList.results,  to.fullPath); // store.dispatch(Actions.LOGOUT);
  }  else{
    // router.push({ name: "sign-in" });
  }
  */
  next();

});

export default router;