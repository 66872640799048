import { Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";

@Module
export default class urlHistoModule extends VuexModule {
  urlHisto = "";

  get getUrlHisto() {
    return this.urlHisto;
  }

  @Mutation
  [Mutations.SET_URLHISTO_MUTATION](payload) {
    this.urlHisto = payload;
  }
}
